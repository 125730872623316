// requires: is.js swiper.js
jQuery(window).ready(function ($) {
    // if (!$("body").hasClass("page-template-page-full-width-php") && !$("body").hasClass('home')) {
    //     setTimeout(function () {
    //         window.location.reload(1);
    //     }, 360000);
    // }

    function closeSearch() {
        $(".search-form-wrap").removeClass("opened");
    }

    function closeSubscription() {
        $(".subscription__form__wrap").removeClass("opened");
    }

    $(".search-btn").click(function (e) {
        e.preventDefault();
        $(".search-form-wrap").addClass("opened");
    });
    $(".subscribe-btn").click(function (e) {
        e.preventDefault();
        $(".subscription__form__wrap").addClass("opened");
    });
    $(".search-btn-close").click(function (e) {
        e.preventDefault();
        closeSearch();
    });
    $(".subscription-btn-close").click(function (e) {
        e.preventDefault();
        closeSubscription();
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() >= 100) {
            $('#header__central').addClass('sticky');
        } else {
            $('#header__central').removeClass('sticky');
        }
    });

    $("body").click(function (e) {
        if (!$(e.target).is('a,input') && !$(e.target).closest('.search-container').length) {
            $(".search-container").find("form").removeClass("form-visible");
            $(".search-container").find("form").addClass("form-unvisible");

            $(".subscribe-container").find("form").removeClass("form-visible");
            $(".subscribe-container").find("form").addClass("form-unvisible");
        }
    });

    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            closeSearch();
            closeSubscription();
        }
    });

    $("a[rel='scrollto']").click(function (e) {
        e.preventDefault();
        //$('body').scrollTo($(this).attr("href"));
        $('html, body').animate({
            scrollTop: $($(this).attr("href")).offset().top
        }, 1000);
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 70) {
            $('.search-container').addClass('show-logo');
            $('.scrollToTop').fadeIn();
        } else {
            $('.search-container').removeClass('show-logo');
            $('.scrollToTop').fadeOut();
        }
    });
    $('.scrollToTop').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });


    $(".nav--ham").on("click", function () {
        $(".menu__wrapper").not("#menu-brands").removeClass("opened");
        $(".menu-item-parent").removeClass("opened");
        $(this).toggleClass('opened');
        $("#menu-brands").toggleClass('opened');
    });
    $("#menu-item-read").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".menu__wrapper").not("#menu-read").removeClass("opened");
        $(".menu-item-parent").not("#menu-item-read").removeClass("opened");
        $(this).toggleClass('opened');
        $("#menu-read").toggleClass('opened');
        $(".nav--ham").removeClass("opened");
    });
    $("#menu-item-watch").on("click", function (e) {
        e.preventDefault();
        $(".menu__wrapper").not("#menu-watch").removeClass("opened");
        $(".menu-item-parent").not("#menu-item-watch").removeClass("opened");
        $(this).toggleClass('opened');
        $("#menu-watch").toggleClass('opened');
        $(".nav--ham").removeClass("opened");
    });
    $("#menu-item-shop").on("click", function (e) {
        e.preventDefault();
        $(".menu__wrapper").not("#menu-shop").removeClass("opened");
        $(".menu-item-parent").not("#menu-item-shop").removeClass("opened");
        $(this).toggleClass('opened');
        $("#menu-shop").toggleClass('opened');
        $(".nav--ham").removeClass("opened");
    });


    // var sliderSmall = document.getElementsByClassName('slick-slider-small');
    //
    //
    // for (var i = 0; i < sliderSmall.length; i++) {
    //     new Splide(sliderSmall[i], {
    //         type: 'loop',
    //         perPage: 7,
    //         gap: "10px",
    //         arrows: true,
    //         pagination: false,
    //         lazyLoad: 'nearby',
    //         // using "ratio" endpoints
    //         breakpoints: {
    //             480: {
    //                 perPage: 1
    //             },
    //             768: {
    //                 perPage: 2
    //             },
    //             1024: {
    //                 perPage: 4
    //             },
    //             1920: {
    //                 perPage: 4
    //             },
    //             3600: {
    //                 perPage: 7
    //             },
    //         }
    //     }).mount();
    // }
    new Splide('.slick-slider-small', {
        type: 'loop',
        perPage: 7,
        gap: "10px",
        arrows: true,
        pagination: false,
        lazyLoad: 'nearby',
        // using "ratio" endpoints
        breakpoints: {
            480: {
                perPage: 1
            },
            768: {
                perPage: 2
            },
            1024: {
                perPage: 4
            },
            1920: {
                perPage: 4
            },
            3600: {
                perPage: 7
            },
        }
    }).mount();
    bannerFooterTakeover(document);
});

function loadBannerTop(el){
    jQuery(el).find('.top-banner').append('<div id="ado-0uKsWyboTbo7QSls6YBRy52XLPLW.OciddP.tSb6aYb.O7"></div>');
    ado.placement({id: 'ado-0uKsWyboTbo7QSls6YBRy52XLPLW.OciddP.tSb6aYb.O7', server: 'rs.adocean.pl'});
}

function loadBannerBottom(el){
    jQuery(el).find('.banner-bottom').append('<div id="ado-lRdamhCpU4p54xHenDZXuKDIwzPXENIxidimJ_.7KET.s7"></div>');
    ado.placement({id: 'ado-lRdamhCpU4p54xHenDZXuKDIwzPXENIxidimJ_.7KET.s7', server: 'rs.adocean.pl'});
}

function bannerMobileTakeover(el){
    jQuery(el).find('.banner-bottom-takeover').append('<div id="ado-H7WnbMlll6nS3XXiit9dQMaoEshlk730R6Dw3vwRdkL.b7"></div>');
    ado.placement({id: 'ado-H7WnbMlll6nS3XXiit9dQMaoEshlk730R6Dw3vwRdkL.b7', server: 'rs.adocean.pl'});
}

function bannerFooterTakeover(el){
    jQuery(el).find('.banner-footer-takeover').append('<div id="ado-sTelsv9rTbOVNT7TbQheZokLw2Xw4NKdZZscOThTEp..47"></div>');
    ado.placement({id: 'ado-sTelsv9rTbOVNT7TbQheZokLw2Xw4NKdZZscOThTEp..47', server: 'rs.adocean.pl'});
}

function loadBanner300(el){
    jQuery(el).find('.banner-300').append('<div id="ado-ulwAugSENzYJmT0VXA7HwlUbTN1NjxAciZdPNj_TXc7.L7"></div>');
    ado.placement({id: 'ado-ulwAugSENzYJmT0VXA7HwlUbTN1NjxAciZdPNj_TXc7.L7', server: 'rs.adocean.pl'});
}

function loadBanner600(el){
    jQuery(el).find('.banner-600').append('<div id="ado-i232fwbGMm7JV1XOLQnb2vHKLNAfZtCmxBP86dVTHk3.K7"></div>');
    ado.placement({id: 'ado-i232fwbGMm7JV1XOLQnb2vHKLNAfZtCmxBP86dVTHk3.K7', server: 'rs.adocean.pl'});
}

function loadSingleBanners(el){
    loadBannerBottom(el);
    loadBanner300(el);
    loadBanner600(el);
    loadBannerTop(el);
    bannerMobileTakeover(el);
}