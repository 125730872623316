jQuery(document).ready(function() {  
  
    jQuery(document).on('click',".voting a",function(){
      
        heart = jQuery(this);  
      
        // Retrieve post ID from data attribute  
        post_id = heart.data("post_id");  
          
        // Ajax call  
        jQuery.ajax({  
            type: "post",  
            url: ajaxVar.ajaxurl,
            data: "action=post-like&nonce="+ajaxVar.nonce+"&post_like=&post_id="+post_id,
            success: function(count){  
                // If vote successful  
                if(count != "already")  
                {  
                    heart.addClass("voted");  
                    heart.siblings(".count").text(count);  
                }  
            }  
        });  
          
        return false;  
    })  
}) 