jQuery(document).ready(function () {

    jQuery("#subscription-form").on('submit', function () {

        var nonce = jQuery(this).find("input[name='nonce']").val();
        var email = jQuery(this).find("input[name='email']").val();
        var accept = jQuery(this).find("input[name='accept']:checked").val();
        var form = jQuery(this);

        console.log(accept);
        jQuery.ajax({
            type: "post",
            url: ajaxVar.ajaxurl,
            dataType : "json",
            data: "action=NewsletterAjax&nonce=" + nonce + "&email=" + email + "&accept="+accept,
            success: function (response) {
                form.find('.messages div').html("");
                form.find('.messages div').removeClass();
                if(response.error == true){
                    form.find('.messages div').append(response.message).addClass('error');
                }else{
                    form.find('.messages div').append(response.message).addClass('success');
                    form.not(':button, :submit, :reset, :hidden')
                        .val('')
                        .prop('checked', false)
                }
            }
        });
        return false;
    })
}) 